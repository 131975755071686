@import "../../scss/mixins";

.highstreet-select {
  box-sizing: border-box;
  // height: 430px;
  margin-bottom: 30px;
  padding: 35px 0 40px;
  // box-shadow: 0px 7px 8px rgba(0, 0, 0, 0.2);
  filter: drop-shadow(0px 7px 8px rgba(0, 0, 0, 0.2));
  // filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5));
  // 0px 7px 8px rgba(0, 0, 0, 0.2

  display: flex;
  flex-direction: column;

  align-items: center;

  box-shadow: none;
  position: relative;
  z-index: 2;

  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    clip-path: polygon(0% 0%, 100% 0%, 100% 410px, 0% 380px);
    background: linear-gradient(
      135deg,
      var(--color-american-mustard) 16.7%,
      var(--color-mint-green) 83.95%
    );
    top: 0;
    z-index: -1;
  }

  @include mobileLandscape {
    &::before {
      clip-path: polygon(0% 0%, 100% 0%, 100% 390px, 0% 365px);
    }
  }

  @include tablet {
    max-width: 800px;
    margin: auto;
    &::before {
      clip-path: polygon(0% 0%, 100% 0%, 100% 350px, 0% 300px);
    }
  }

  @include laptop {
    padding: 30px 0 0 0;
    max-width: 850px;
    margin: auto;
    height: 485px;

    &::before {
      clip-path: polygon(0% 0%, 100% 0%, 100% 420px, 0% 360px);
    }
  }

  @include desktop {
    height: 505px;
    max-width: 922px;
    padding: 30px 0 0 0;

    &::before {
      clip-path: polygon(0% 0%, 100% 0%, 100% 450px, 0% 390px);
    }
  }

  .offer-banner {
    background-color: var(--color-cornflower-blue);
    color: #ffffff;
    display: flex;
    align-items: center;
    font-weight: bold;
    position: absolute;
    top: -26px;
    left: 0;
    right: 0;
    padding: 5px 115px 5px 15px;
    font-size: 13px;
    line-height: 16px;
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.5);

    @include tablet {
      left: 30px;
      right: 30px;
    }

    @include laptop {
      font-size: 21px;
      line-height: 26px;
      padding: 8px 15px;
    }

    @include desktop {
      left: 60px;
      right: 60px;
    }

    span {
      margin-left: 4px;
      text-transform: uppercase;
    }

    img {
      position: absolute;
      right: 0;
      top: -23px;
      z-index: 2;
      width: 125px;

      @include laptop {
        right: -5px;
        top: -45px;
        width: auto;
      }
    }
  }

  .top {
    h1 {
      font-size: 36px;
      font-weight: 700;
      letter-spacing: -0.03em;
      color: white;
      text-shadow: 0px 5px 8px rgba(0, 0, 0, 0.2);
      text-align: center;
      font-size: 58px;
      line-height: 54px;

      @include mobileLandscape {
        font-size: 46px;
        line-height: 50px;
        padding: 0 10px;
      }

      @include tablet {
        padding: 0;
        font-size: 58px;
      }

      @include laptop {
        font-size: 65px;
        line-height: 86px;
      }

      @include desktop {
        font-size: 85px;
        line-height: 106px;
      }
    }

    h2 {
      font-size: 24px;
      font-weight: 400;
      color: white;
      text-align: center;

      @include mobile {
        font-size: 24px;
        margin-top: 10px;
      }

      @include mobileLandscape {
        font-size: 18px;
      }

      @include tablet {
        font-size: 25px;
      }

      @include laptop {
        font-size: 30px;
      }

      @include desktop {
        font-size: 40px;
      }
    }
  }

  .bottom {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -40px;

    @include mobileLandscape {
      margin-top: 0;
    }

    .foodbox {
      position: relative;
      margin-left: -60px;
      z-index: -1;

      img {
        max-width: 196px;
        @include tablet {
          max-width: 264px;
        }
        @include laptop {
          max-width: 100%;
        }
      }

      @include mobileLandscape {
        margin-left: 0;
      }

      @include tablet {
        margin-left: -30px;
      }

      @include laptop {
        top: -30px;
        left: -10px;
        margin-left: 0;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      max-width: 465px;
      justify-content: center;
      box-sizing: border-box;
      @include tablet {
        margin-top: 40px;
        justify-content: unset;
      }
      @include laptop {
        margin-top: 15px;
      }
    }

    h2 {
      color: #ffffff;
      font-weight: normal;
      font-size: 18px;
      line-height: 25px;
      max-width: 180px;
      margin-right: -20px;
      margin-top: 20px;

      strong {
        font-weight: bold;
      }

      @include mobileLandscape {
        max-width: 220px;
        margin-right: 0;
        margin-top: 0;
      }

      @include tablet {
        max-width: 325px;
        font-size: 24px;
        line-height: 32px;
        margin-top: 0;
      }

      @include laptop {
        font-size: 34px;
        line-height: 42px;
        max-width: 100%;
      }

      @include desktop {
        font-size: 40px;
        line-height: 50px;
      }
    }

    .mySelectDefault {
      position: relative;
      @include mobile {
        max-width: 300px;
      }
      @include mobileLandscape {
        max-width: 320px;
      }
      @include tablet {
        max-width: 429px;
      }
      @include laptop {
        max-width: 460px;
      }
      @include desktop {
        max-width: 446px;
      }
      &.selectClosed,
      &.selectOpen {
        &:before {
          pointer-events: none;
          position: absolute;
          z-index: 999;
          top: 50%;
          left: 5%;
          content: "Select neighbourhood";
          transform: translateY(-40%);
          font-size: 18px;
          font-weight: bold;
          line-height: 35px;
          letter-spacing: -0.03em;
          color: #ff005e;
        }
      }
    }

    p {
      font-weight: 300;
      font-size: 25px;
      color: white;
      text-align: center;
      max-width: 795px;

      @include mobile {
        font-size: 14px;
      }

      @include mobileLandscape {
        font-size: 17px;
      }

      @include tablet {
        font-size: 18px;
        line-height: 22px;
        max-width: 450px;
      }

      @include laptop {
        font-size: 20px;
        line-height: 25px;
        max-width: 610px;
      }

      @include desktop {
        font-size: 25px;
        line-height: 31px;
        max-width: 795px;
      }
    }
  }

  .form-container {
    @include laptop-down {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &--form {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    box-sizing: border-box;
    max-width: 420px;
    width: 100%;
    margin: 0 10px;
    @include mobileLandscape {
      margin: 0;
    }
    @include tablet {
      position: absolute;
      right: 0;
      top: 160px;
      max-width: 325px;
    }
    @include laptop {
      right: 40px;
      top: 170px;
      max-width: 420px;
    }
    @include desktop {
      top: 184px;
      right: 0;
      max-width: 460px;
    }
    .field-postcode {
      height: 69px;
      font-size: 25px;
      line-height: 35px;
      background: #ffffff;
      color: var(--color-raspberry-sorbet);
      font-size: 25px;
      line-height: 35px;
      font-weight: normal;
      box-sizing: border-box;
      border: none;
      outline: none;
      display: block;
      padding: 12px;
      width: 100%;
      &::placeholder {
        color: var(--color-raspberry-sorbet);
        opacity: 1;
      }
      &:-ms-input-placeholder {
        color: var(--color-raspberry-sorbet);
      }
      &::-ms-input-placeholder {
        color: var(--color-raspberry-sorbet);
      }
      @include tablet {
        height: 50px;
        font-size: 18px;
      }
      @include laptop {
        height: 69px;
        font-size: 25px;
        line-height: 35px;
        padding: 18px;
      }
    }
    .postcode-btn {
      background-color: var(--color-raspberry-sorbet);
      color: #ffffff;
      height: 55px;
      line-height: 55px;
      padding: 0 18px;
      font-weight: bold;
      font-size: 18px;
      cursor: pointer;
      margin-right: 8px;
      white-space: nowrap;
      @include tablet {
        height: 38px;
        line-height: 38px;
      }
      @include laptop {
        height: 55px;
        line-height: 55px;
        margin-right: 8px;
      }
    }
  }
}

.money-icon {
  background-image: url("../../assets/home-hero/money-icon.svg");
  width: 34px;
  height: 36px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 34px;
  display: inline-block;
  margin-right: 10px;
  flex-shrink: 0;
}
