.shop-product-customisation {
  position: relative;
  display: grid;
  overflow: auto;
  height: calc(100% - 40px);
  margin-left: -20px;
  margin-right: -20px;
  padding: 0 20px;

  &-title {
    display: grid;
    font-weight: 700;
    font-size: 20px;
    margin: 0 auto;
    border-bottom: 3px solid var(--color-mint-green);
    text-align: center;
    margin-bottom: 20px;
  }

  &-choice-title {
    display: flex;
    font-weight: 700;
    font-size: 20px;
    border-bottom: 3px solid var(--color-mint-green);
    text-align: center;
    padding-bottom: 8px;
  }

  &-close {
    cursor: pointer;
    top: 10px;
    right: 10px;
    position: absolute;
    border: none;
    padding: 0;
    outline: none;
    width: 28px;
    height: 28px;
    background: #fff;
    z-index: 2;
    &:after,
    &:before {
      position: absolute;
      content: '';
      height: 100%;
      width: 1.5px;
      background: var(--color-raspberry-sorbet);
      top: 0;
      transform: rotate(-45deg);
    }
    &:after {
      transform: rotate(45deg);
    }
  }
  &-update {
    position: absolute;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 22;
    bottom: 0;
    right: 0;
    left: 0;
    background: #fff;
    box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.15);

    button {
      height: 40px;
    }
  }
  &-choice {
    margin-bottom: 10px;
    margin-top: 8px;
    padding: 0 10px;

    &-title {
      font-weight: 700;
      font-size: 15px;
      margin-bottom: 5px;
    }

    &-option {
      display: grid;
      grid-template-columns: min-content auto max-content;
      gap: 5px;

      margin-bottom: 15px;

      font-size: 14px;
      font-weight: 600;

      input[type='checkbox'] {
        opacity: 0;
        text-indent: -9999px;

        & + label {
          position: relative;
          padding-right: 30px;

          &::before {
            position: absolute;
            content: '';
            width: 12px;
            border: 2px solid #8c8c8c;
            left: -18px;
            height: 12px;
            box-sizing: border-box;
            top: 2px;
          }
        }

        &:checked {
          & + label {
            &::before {
              background-image: url('../../assets/product-detailed/tick-green.svg');
              border-color: transparent;
              background-size: 16px;
              background-position: center;
            }
          }
        }
      }
    }
  }
}
