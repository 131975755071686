@import "../../scss/mixins";

.login-or-register {
  h1 {
    color: var(--color-raspberry-sorbet);
    font-weight: 700;
    font-size: 21px;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 16px;

    @include mobileLandscape {
      font-size: 22px;
    }

    @include tablet {
      font-size: 28px;
    }

    @include laptop {
      font-size: 30px;
    }

    @include desktop {
      margin-top: 100px;
      font-size: 36px;
    }
  }

  form {
    display: grid;
    padding: 10px 38px 38px;
    gap: 12px;
    max-width: 580px;
    margin: auto;

    @include tablet {
      padding: 38px;
    }

    .grid-2-wide {
      display: grid;
      gap: 12px;

      @include mobileLandscape {
        grid-template-columns: 1fr 1fr;
      }
    }

    .address {
      display: grid;
      grid-template-columns: 1fr 3fr;
      gap: 12px;
    }

    input[type="email"],
    input[type="password"],
    input[type="text"],
    input[type="number"] {
      height: 54px;
      padding: 14px 26px;
      font-size: 18px;
      border-color: var(--color-american-mustard);
      border-width: 3px;
      border-style: solid;
      width: 100%;
      box-sizing: border-box;

      @at-root {
        .container-register & {
          border-color: var(--color-mint-green);
        }
      }
    }

    input[type="password"] {
      &:last-child {
        margin-bottom: 25px;
      }
    }

    input[type="submit"] {
      background-color: var(--color-american-mustard);
      border-style: none;
      padding: 13px 50px;
      width: fit-content;
      margin: auto;
      color: #FFFFFF;
      font-size: 18px;
      font-weight: 700;
      height: 60px;

      @at-root {
        .container-register & {
          background-color: var(--color-mint-green);
        }
      }
    }

    p {
      text-align: center;
      cursor: pointer;
      color: var(--color-mid-charcoal);
      font-weight: 700;
      font-size: 18px;
      &.register-label{
        color : var(--color-mint-green);
        cursor: default;
        margin-top: 50px;
        margin-bottom: 5px;
      }
    }

    .newsletter {
      display: flex;
      flex-direction: row;
      align-items: center;

      input {
        width: 26px;
        height: 26px;
      }

      label {
        margin-left: 12px;
        color: var(--color-mid-charcoal);
        font-size: 15px;
        font-weight: 700;
      }
    }
    .error {
      color: var(--color-raspberry-sorbet);
    }
  }
}

.error {
  color: var(--color-error) !important;
}

.new-to-localo {
  display: flex;
  align-items: center;

  span {
    margin-right: 8px;
    font-size: 16px;
    line-height: 25px;
    font-weight: 700;
    color: #8c8c8c;

    @include mobileLandscape {
      font-size: 20px;
      margin-right: 15px;
    }
  }
}

.create-acc-button {
  text-align: center;

  button {
    background-color: var(--color-mint-green);
    border-style: none;
    padding: 13px 30px;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 700;
    height: 60px;
    box-sizing: border-box;
  }
}