@import '../../scss/mixins';


.votw-wrapper {
    position: relative;
    .img-wrapper {
        @include mobile{
            &:before {
                content: '';
                background-image: url('../../assets/votw/vector.png');
                background-position: center;
                background-repeat: no-repeat;
                width: 330px;
                display: inline;
                position: absolute;
                z-index: 10;
                left: 10px;
                height: 420px;
                background-size: cover;
                bottom: 45px;
            }
            img {
                max-width: 350px;
                position: absolute;
                bottom: 0;
                z-index: 10;
                left: -0px;
            }
        }
        @include mobileLandscape{
            &:before {
                content: '';
                background-image: url('../../assets/votw/vector.png');
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                width: 200px;
                height: 100%;
                display: inline;
                position: absolute;
                top: 0px;
                left: 0;
            }
            img {
                max-width: 200px;
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }
        @include tablet {
            &:before {
                content: '';
                background-image: url('../../assets/votw/vector.png');
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                width: 430px;
                height: 115%;
                display: inline;
                position: absolute;
                top: -80px;
                left: -130px;
                transform: rotate(0);
            }
            img {
                max-width: 290px;
                position: absolute;
                bottom: 0;
                left: -60px;
                transform: rotate(0);
            }
        }
        @include laptop {
            &:before {
                content: '';
                background-image: url('../../assets/votw/vector.png');
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                width: 430px;
                height: 100%;
                display: inline;
                position: absolute;
                top: -50px;
                left: 0;
                transform: rotate(0);
            }
            img {
                max-width: 330px;
                left: 50px;
            }
        }
        @include desktop {
            &:before {
                content: '';
                background-image: url('../../assets/votw/vector.png');
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                width: 630px;
                height: 100%;
                display: inline;
                position: absolute;
                top: -70px;
                left: 30px;
                transform: rotate(0);
            }
            img {
                max-width: 530px;
                position: absolute;
                bottom: 0;
                transform: rotate(0);
                left: 80px;
            }
        }
       
    }
    
}

.vendor-of-the-week {
    background: linear-gradient(135deg, var(--color-cornflower-blue) 16.7%, var(--color-mint-green) 83.95%);
    clip-path: polygon(0% 40px, 100% 0%, 100% 100%, 0% 100%);

    @include mobile {
        padding: 55px 0 35px 0;
        padding-bottom: 500px;
    }
    
    @include mobileLandscape{
        padding: 35px 0;
    }
    @include tablet {
        padding: 45px 0;
    }

    @include laptop {
        padding: 75px 0;
    }

    @include desktop {
        padding: 150px 0;
    }

    .content {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;

        @include mobileLandscape {
            grid-template-columns: min-content auto;
            position: relative;
            top: 30px;
        }

        @include tablet {
            top: 0;
        }
        
        @include laptop {
            position: relative;
            top: 0;
        }
        
        @include desktop {
            position: relative;
            left: -120px;
            top: 20px;
        }
    }

    img {
        width: auto;
        align-self: flex-end;
        justify-self: center;
        position: relative;
        
        @include mobileLandscape {
            grid-column: 1;
            max-width: 180px;
        }

        @include tablet {
            max-width: 250px;
        }

        @include desktop {
            max-width: 550px;

        }

        
    }

    .text {
        width: 300px;
        color: white;
        max-width: 590px;
        @include mobile {
            grid-column: 2;
            width: 295px;
            margin: 0 auto;
            top: 20px;
        }

        @include mobileLandscape {
            grid-column: 2;
            width: 240px;
            margin: 0;
        }

        @include tablet {
            width: 355px;
            position: relative;
            top: 30px;
            text-align: left;
            margin: 0;
        }

        @include laptop {
            width: 400px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: left;
            margin: 0;
            left: -100px;
        }

        @include desktop {
            width: 800px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: left;
            margin: 0;
            left: 0;
        }

        h2 {
            text-align: center;
            font-size: 28px;
            
            @include mobileLandscape {
                font-size: 24px;
                text-align: left;
            }
            
            @include tablet {
                font-size: 24px;
            }

            @include laptop {
                font-size: 40px;
                margin-bottom: 5px;
            }
            
            @include desktop {
                font-size: 48px;
                margin-bottom: 0;
            }
        }

        .slick-slider {
            margin-bottom: 20px;
            margin-top: 10px;
            @include laptop {
                margin-top: 30px;
                margin-top: 0;
            }
            @include desktop {
                margin-top: 30px;
            }
            .votw-text {
                padding-bottom: 40px;
                p {
                    font-weight: 400;
                    text-align: center;
                    font-size: 20px;
                    font-weight: 300;
                    @include mobileLandscape {
                        font-size: 17px;
                    }
                    
                    @include tablet {
                        font-size: 20px;
                    }
                    
                    @include desktop {
                        font-size: 20px;
                    }
                }
            }

            @include mobileLandscape {
                margin-bottom: 50px;

                .votw-text {
                    padding-bottom: 20px;

                    p {
                        font-size: 20px;
                        text-align: left;

                        @include mobileLandscape {
                            font-size: 16px;
                        }
                        
                        @include tablet {
                            font-size: 20px;
                        }
                        
                        @include laptop {
                            font-size: 22px;
                        }
                        
                        @include desktop {
                            font-size: 30px;
                        }
                    }
                }
            }

            @include tablet {
                .votw-text {
                    font-size: 20px;
                }
            }

            .slick-dots {
                text-align: center;
                @include desktop {
                    text-align: left;
                }
                li {
                    button {
                        padding: 0;
                        &::before {
                            content: '';
                            width: 8px;
                            height: 8px;
                            border: 2px solid white;
                            border-radius: 8px;
                            opacity: 1;

                            @include desktop {
                                width: 15px;
                                height: 15px;
                                border-radius: 15px;
                            }
                        }
                    }

                    &.slick-active {
                        button {
                            &::before {
                                background-color: white;
                            }
                        }
                    }
                }
            }
        }
    }
}
