@import '../../scss/mixins';

.shop-product-detailed {
  @include laptop {
    display: flex;
    flex-direction: column;
  }

  @include desktop {
    display: grid;
    grid-template-columns: 563px 321px;
    gap: 30px;
  }

  margin-bottom: 24px;
  min-height: 75vh;
  align-items: flex-start;
  position: relative;

  &.blur {
    filter: blur(5px);
  }

  &-left {
    &-size {
      position: relative;
      width: 154px;
      z-index: 9;
      margin: 0 auto 16px;
      .mySelectDefault {
        margin-right: 0;
        max-width: 100%;

        .fieldset {
          margin-top: 0;
          box-sizing: border-box;
          border: 2px solid #8ddc73;
          padding: 0;
          box-sizing: border-box;
          font-size: 18px;

          @include laptop {
            font-size: 20px;
          }

          .selectedDisplay {
            color: #8ddc73;
            font-weight: 700;

            @include laptop {
              font-weight: 400;
            }
          }

          .select_option {
            @include mobile {
              padding: 0.8em 0.5em;
            }
            @include tablet {
              padding: 0.5em 0 0.5em 0.5em;
            }
          }
        }

        .mainSectionWrapper {
          min-height: 44px;
          cursor: pointer;

          .cancelContainer {
            display: none;
          }

          .downArrowContainer {
            .downArrowContent {
              & > svg {
                fill: var(--color-mint-green);
                border: 2px solid var(--color-mint-green);
                border-radius: 50%;
                box-sizing: border-box;
              }
            }
          }
        }
      }
      @include desktop {
        width: 100%;
      }
    }
    &-details {
      display: flex;
      flex-direction: column;
      gap: 10px;

      font-weight: 700;

      margin-bottom: 14px;

      h3 {
        color: #373f41;
        font-size: 16px;
        text-align: left;

        @include tablet {
          display: none;
        }
      }

      &-title {
        color: #373f41;
        @include mobile {
          font-size: 17px;
        }

        @include laptop {
          font-size: 20px;
        }
      }

      &-get-it {
        color: var(--color-cornflower-blue);
        font-size: 12px;
        line-height: normal;
        font-style: italic;
        font-weight: normal;

        @include tablet() {
          font-size: 18px;
          line-height: 21px;
        }
      }

      &-size {
        font-weight: bold;
        font-size: 15px;
        line-height: 19px;
        letter-spacing: -0.03em;
        color: #000000;
      }

      &-description {
        font-size: 15px;
      }
    }
    &-images {
      @include mobile {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
      }

      @include laptop {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
      }

      @include desktop {
        display: unset;
        flex-wrap: unset;
      }

      &-thumbnail {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 50%;
        max-width: 50%;
        margin-bottom: 10px;

        @include mobile {
          flex: 0 0 100%;
          max-width: 100%;
          height: 238px;
        }

        @include mobileLandscape {
          flex: 0 0 62%;
          max-width: 62%;
          height: auto;
        }

        @include tablet {
          flex: 0 0 100%;
          max-width: 100%;
        }

        @include desktop {
          margin-bottom: 15px;
        }

        &-container {
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: cover;

          @include mobile {
            width: 100%;
            height: 238px;
          }

          @include mobileLandscape {
            width: 265px;
            height: 131px;
          }

          @include tablet {
            width: 576px;
            height: 334px;
          }

          @include laptop {
            width: 619px;
            height: 334px;
          }
          @include desktop {
            width: 563px;
            height: 334px;
          }
        }

        img {
          object-fit: fill;
          width: 100%;
          height: 100%;
        }
      }
      &-grid {
        display: flex;
        justify-content: flex-start;
        gap: 9px;
        @include mobile {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          flex: 0 0 100%;
        }

        @include mobileLandscape {
          gap: 8px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          flex: 0 0 36%;
          max-width: 36%;
          justify-content: unset;
        }

        @include tablet {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          flex: 0 0 100%;
          max-width: 100%;
          gap: 10px;
          justify-content: unset;
        }

        @include laptop {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          flex: 0 0 100%;
          max-width: 100%;
          gap: 10px;
        }

        @include desktop {
          display: flex;
          gap: 15px;
          flex: 0 0 100%;
          max-width: 100%;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: unset;
        }

        &-item {
          max-width: calc(16% - 6px);
          height: 50px;
          flex: 0 0 calc(16% - 6px);

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          @include mobileLandscape {
            max-width: 46px;
            height: 61px;
            flex: 0 0 46px;
          }

          @include tablet {
            max-width: 87px;
            height: 63px;
            flex: 0 0 87px;
          }

          @include laptop {
            max-width: 95px;
            height: 63px;
            flex: 0 0 95px;
          }
          @include desktop {
            max-width: 100px;
            height: 65px;
            flex: 0 0 100px;
          }
        }
      }
    }
  }

  &-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 15px;
    margin-bottom: 20px;
    position: relative;
    margin-top: 15px;
    @include mobileLandscape {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0;
    }
    @include mobileLandscape {
      margin-top: 10px;
    }
    @include tablet {
      margin-top: 30px;
    }
    @include desktop {
      display: flex;
      margin-top: 65px;
      margin-bottom: 0;
    }

    &-hero {
      display: flex;
      flex-direction: column;
      position: relative;
      margin-bottom: 30px;
      // order: 3;
      padding-top: 50px;

      @include mobileLandscape {
        // padding-left: 180px;
        margin-bottom: 15px;
        margin-top: 0;
        // order: 0;
        padding-top: 0;

        // &.no-quick-delivery {
        //   padding-left: 0;
        // }
      }

      // @include tablet {
      //   padding-left: 295px;

      //   &.no-quick-delivery {
      //     padding-left: 295px;
      //   }
      // }

      // @include laptop {
      //   padding-left: 315px;

      //   &.no-quick-delivery {
      //     padding-left: 315px;
      //   }
      // }

      @include desktop {
        margin-top: 22px;
        // padding-left: 0;
        margin-bottom: 20px;
        // order: 3;

        // &.no-quick-delivery {
        //   padding-left: 0;
        // }
      }
    }

    &-delivery-note {
      border-top: 2px solid #bfbfbf;
      border-bottom: 2px solid #bfbfbf;
      box-sizing: border-box;
      color: var(--color-raspberry-sorbet);
      font-size: 18px;
      font-weight: 300;
      padding: 6px 5px;
      text-align: center;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      z-index: 1;
      // order: 1;

      img {
        margin-right: 5px;
      }

      .same-day {
        font-weight: bold;
        margin-right: 3px;

        @include mobileLandscape {
          margin-bottom: -3px;
        }

        @include tablet {
          font-size: 21px;
          line-height: 17px;
          margin-bottom: 0;
          margin-right: 0;
        }
      }

      & ~ .shop-product-detailed-right-description {
        margin-top: 0 !important;
      }

      @include mobile {
        margin-top: 20px;
      }

      @include mobileLandscape {
        // order: 0;
        margin-bottom: 15px;
        align-items: flex-start;
        flex-direction: column;
        position: absolute;
        font-size: 14px;
        padding-left: 40px;
        left: 0;
        top: 30px;
        margin: 0;
        height: 32px;
        width: 170px !important;

        img {
          width: 18px;
          position: absolute;
          left: 10px;
          margin-right: 0;
        }
      }

      @include tablet {
        flex-direction: row;
        align-items: center;
        font-size: 18px;
        gap: 3px;
        top: 35px;
        padding: 0;
        height: 40px;
        width: 275px !important;

        img {
          position: static;
          width: 22px;
        }
      }

      @include laptop {
        width: 300px !important;
        top: 37px;
      }

      @include desktop {
        height: 52px;
        margin-bottom: 18px;
        position: relative;
        padding: 6px 0;
        // order: 1;
        top: 0;
        width: 100% !important;

        img {
          width: 30px;
        }
      }
    }

    &-tags {
      display: flex;
      flex-wrap: wrap;
      gap: 7px;
      margin-bottom: 30px;
      width: 100%;
      &-item {
        cursor: pointer;
        background-color: #e2e2e2;
        color: var(--color-mid-charcoal);
        font-size: 12px;
        font-weight: bold;
        height: 30px;
        line-height: 30px;
        letter-spacing: -0.03em;
        text-align: center;
        border-radius: 30px;
        flex: 0 0 30.3333%;
        max-width: 30.3333%;
        outline: none;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding: 0 8px;
        box-sizing: border-box;

        &.active {
          background-color: var(--color-mint-green);
          color: #000000;
        }

        &:hover {
          background-color: #bfbfbf;
          color: #000000;
        }

        &:active {
          background-color: #8c8c8c;
          color: #000000;
        }
      }
    }

    &-delivered {
      color: #000000;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 15px;
      text-align: center;

      @include mobile {
        margin-top: 15px;
      }

      @include mobileLandscape {
        font-size: 17px;
        line-height: 22px;
        text-align: left;
        margin-top: 0;
      }

      @include desktop {
        text-align: center;
      }
    }

    .shop-product-detailed-right-buttons {
      margin: 10px auto 0;
      width: 100%;
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        &.button-green-border {
          background-color: transparent;
          color: #55c234;
          border: #55c234 3px solid;
          max-width: 226px;
          margin-left: auto;
          margin-right: auto;

          .add-to-cart-icon {
            width: 28px;
            height: 26px;
            margin-right: 5px;
            display: none;
            background-repeat: no-repeat;
            background-image: url('../../assets/product-detailed/prod-add-to-cart-green.svg');
            background-position: center;

            @include laptop {
              display: inline-block;
            }
          }

          &:hover {
            background-color: #55c234 !important;
            border-color: #55c234 !important;
            color: white;

            .add-to-cart-icon {
              background-image: url('../../assets/product-detailed/prod-add-to-cart.svg');
            }
          }

          @include mobileLandscape {
            max-width: unset;
            margin-left: 0;
            margin-right: 0;
          }
        }
        &.button-green-solid {
          background-color: #55c234 !important;
          border-color: #55c234 !important;
          color: white !important;
          border-width: 3px !important;
          width: 100%;
          padding-left: 15px;
          padding-right: 15px;
          position: absolute;
          top: 0;
          left: 50%;

          transform: translateX(-50%);
          &:hover {
            background-color: #ffffff !important;
            color: #55c234 !important;
            border-color: #55c234 !important;
          }
          @include mobileLandscape {
            padding-left: 0;
            padding-right: 0;
            position: static;
            transform: none;
          }

          .clock-icon {
            display: none;
            background-image: url('../../assets/product-detailed/get-it-fast.svg');
            width: 20px;
            height: 22px;
            background-position: center;
            margin-right: 10px;
            background-repeat: no-repeat;

            @include laptop {
              display: inline-block;
            }
          }

          &:hover {
            .clock-icon {
              background-image: url('../../assets/product-detailed/get-it-fast-green.svg');
            }
          }
        }
        img {
          margin-right: 10px;
        }
      }
      @include mobile {
        div {
          flex-wrap: wrap;
          gap: 10px;
        }
        button {
          flex: 1 1 auto;
          min-width: 100px;
        }
      }
      @include tablet {
        margin: 15px auto 0;
        div {
          flex-wrap: wrap;
          gap: 15px;
        }
        button {
          flex: 1 1 auto;
        }
      }
      @include laptop {
        width: auto;
        margin: 17px 0 0;
        flex: 0 0 100%;
        div {
          flex-wrap: wrap;
          gap: 10px;
        }
        button {
          flex: 1 1 auto;
        }
      }
      @include desktop {
        margin: 12px 0;
        flex: unset;
        div {
          max-width: 100%;
          display: flex;
          gap: 16px;
        }
        button {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          img {
            margin-right: 10px;
          }
          .fast {
            background-color: #8ddc73;
            border: #8ddc73;
            color: #fff;
          }
        }
      }
    }

    .shop-product-detailed-right-description {
      color: #373f41;
      display: flex;
      align-items: center;
      flex-direction: column;
      // order: 2;
      ul {
        margin-top: 0;
        li {
          position: relative;
          padding-left: 20px;
          text-align: left;
          margin-top: 5px;
          padding-top: 5px;

          & + li {
            border-top: 1px solid #bfbfbf;
          }

          &::before {
            content: '';
            background-image: url('../../assets/product-detailed/tick-green.svg');
            width: 16px;
            height: 16px;
            display: block;
            position: absolute;
            left: 0;
            top: 8px;
          }
        }
      }
      @include mobile {
        flex: 0 0 100%;
        max-width: 100%;
        p {
          font-size: 14px;
          line-height: 17px;
          letter-spacing: -0.001em;
          margin-bottom: 15px;
          width: 100%;
          text-align: center;
        }
        ul {
          list-style-type: none;
          padding-left: 0;
        }
      }
      @include mobileLandscape {
        flex: 0 0 49%;
        max-width: 48%;
        // order: 0;
        p {
          font-size: 14px;
          line-height: 17px;
          letter-spacing: -0.001em;
          margin-bottom: 20px;
          width: 100%;
          text-align: left;
        }
        ul {
          list-style-type: none;
          padding-left: 0;
        }
      }
      @include tablet {
        flex: 0 0 48%;
        max-width: 48%;
        // margin-top: -60px;
        p {
          font-size: 14px;
          line-height: 17px;
          letter-spacing: -0.001em;
          margin-bottom: 20px;
        }
        ul {
          list-style-type: none;
          padding-left: 0;
        }
      }
      @include laptop {
        flex: 0 0 48%;
        max-width: 48%;
        // margin-top: -55px;
        p {
          font-size: 17px;
          line-height: 22px;
          letter-spacing: -0.001em;
          margin-bottom: 20px;
        }
        ul {
          list-style-type: none;
          padding-left: 0;
        }
      }

      @include desktop {
        flex: 0 0 100%;
        max-width: 100%;
        // order: 2;
        margin-top: 0;
        p {
          width: auto;
          text-align: center;
        }
      }
    }

    .shop-product-detailed-right-shop {
      // order: 4;
      @include mobileLandscape {
        flex: 0 0 48%;
        max-width: 48%;
        margin-top: 0;
        // order: 0;
      }
      @include tablet {
        flex: 0 0 48%;
        max-width: 48%;
      }
      @include desktop {
        flex: 0 0 100%;
        max-width: 100%;
        // order: 4;
        margin-bottom: 40px;
      }
      p + p {
        margin-top: 10px;
      }
    }

    > * {
      text-align: left;

      width: 100%;
    }
    &-price {
      grid-area: a;

      text-align: center;
      color: var(--color-mid-charcoal);
      @include mobile {
        font-weight: 700;
        font-size: 17px;
      }

      @include laptop {
        font-weight: 700;
        // order: -1;
      }

      @include desktop {
        margin-top: 0;
        // order: 0;
        margin-left: 0;
      }
    }
    &-buttons {
      > div {
        display: flex;
        justify-content: space-between;

        @include laptop {
          justify-content: center;
          gap: 16px;
        }

        button {
          font-size: 16px;
          padding: 0;
          height: 40px;

          .customise-link-text {
            text-decoration: underline;
          }

          .customise-btn-text {
            display: none;
          }

          @include mobileLandscape {
            font-size: 13px;
            height: 32px;

            .customise-btn-text {
              display: unset;
            }

            .customise-link-text {
              display: none;
            }
          }

          @include tablet {
            height: 40px;
            font-size: 16px;
          }
        }
      }
    }

    &-description {
      grid-area: c;
      @include mobile {
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
      }
      @include laptop {
        font-size: 17px;
        font-weight: 400;
      }
    }

    &-shop {
      grid-area: d;
      color: #373f41;
      @include mobile {
        font-size: 14px;
        text-align: center !important;
      }
      @include mobileLandscape {
        text-align: left !important;
      }
      @include laptop {
        font-size: 17px;
        font-weight: 400;
      }

      &-title {
        font-weight: 700;
        @include mobile {
          font-size: 14px;
        }
        @include mobileLandscape {
          font-size: 17px;
        }

        margin-bottom: 15px;
      }
    }
  }
}

.select-variant {
  position: absolute;

  z-index: 1;

  margin-top: 20vh;

  p {
    font-size: 2em;
    font-weight: 700;
  }
}

.shop-product-detailed-modal {
  @include mobile {
    height: 85vh;
  }
}

.addtocart_confirm {
  &-container {
    display: flex;
    flex-direction: row;
    background-color: #8c8c8c;
    margin: 10px auto;
    justify-content: space-between;

    @include mobile {
      max-width: 90%;
      padding: 9px;
    }

    @include tablet {
      max-width: 60%;
      padding: 15px 20px;
    }

    @include laptop {
      max-width: 70%;
    }

    @include desktop {
      max-width: 100% !important;
      padding: 9px;
    }
  }

  &-text {
    color: #fff;
    font-size: 15px;
  }

  &-link {
    color: #fff;
    font-size: 15px;
    text-decoration: underline;
  }
}
